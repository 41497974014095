<script setup lang="ts">
import InformationDialog from '@frontend/ui-kit/src/components/Base/InformationDialog.vue';
import Button from '@frontend/ui-kit/src/components/Base/Button.vue';
import { useBooking } from '~/composables/booking/useBooking';
import dateTimeHelper from '@frontend/ui-kit/src/utils/dateTimeHelper';
import type { BookingStatus, IBookingData } from '~/types/booking/booking';
import priceHelper from '@frontend/ui-kit/src/utils/priceHelper';

const { t } = useI18n();
const { getBookingData } = useBooking();

const model = defineModel<boolean>();

const isLoading = ref<boolean>(true);
const data = ref<IBookingData | null>(null);
const isTypeFinishName = ref<string | null>(null);
const props = defineProps({
    bookingId: { type: Number, required: true, default: 0 }
})

const emit = defineEmits(['continue']);

onMounted(async () => {
    await loadData();
    isLoading.value = false;
});

const loadData = async () : Promise<void> => {
    const result = await getBookingData(props.bookingId);
    if(result.isError) {
        model.value = false;
        return;
    }

    data.value = result.data!;
    isTypeFinishName.value = data.value!.realtyInfo.isTypeFinish 
        ? t('booking.common.info_titles.withFinishing') 
        : t('booking.common.info_titles.withoutFinishing');
}

const onContinue = (id: number, status: BookingStatus) => {
    emit('continue', id, status);
}

const onCloseDialog = () => {
    model.value = false;
};
</script>

<template>
    <InformationDialog
        v-model="model"
        :maxWidth="495"
        styleClass="booking-info-dialog"
        :showButton="true"
        @closeDialog="onCloseDialog"
    >
        <template #content>
            <v-skeleton-loader
                v-if="isLoading"
                color="neutral04"
                class="loader"
                type="article, text"
            />
            <div v-else-if="data" class="container">
                <div class="text">
                    <div class="title">{{ $t('booking.client.dialog_continue.title') }}</div>
                    <div class="realty-info">
                        {{ data.realtyInfo.project }} 
                        • {{ data.realtyInfo.address }} 
                        • {{ data.realtyInfo.realtyTypeName }} {{ data.realtyInfo.square }} м² 
                        • {{ isTypeFinishName }}
                    </div>
                    <div class="description">
                        {{ $t('booking.client.dialog_continue.description') }}
                    </div>
                </div>

                <div class="booking-info">
                    <div class="title">
                        {{ $t('booking.client.dialog_continue.parameters') }}
                    </div>
                    <div class="row">
                        <div>{{ $t('booking.client.dialog_continue.term') }} </div>
                        <div>
                            {{ $t('forms.shared.prepositions.until') }} 
                            {{ data.bookingEnd ? dateTimeHelper.toDateString(data.bookingEnd) : "—" }}
                        </div>
                    </div>
                    <div class="row">
                        <div>{{ $t('booking.client.dialog_continue.price') }} </div>
                        <div>{{ priceHelper.toStringWithRuble(data.bookingPrice) }}</div>
                    </div>
                </div>

                <div class="actions">
                    <Button
                        styleClass="dialog-button"
                        @click="onContinue(data.id, data.bookingStatus)"
                    >
                        {{ $t('booking.client.dialog_continue.continue') }}
                    </Button>
                </div>
            </div>
        </template>
    </InformationDialog>
</template>

<style scoped lang="scss">
.container {
    display: flex;
    padding: 0px var(--Padding-pa-6, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Padding-pa-10, 40px);
    align-self: stretch;

    .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Padding-pa-3, 12px);
        align-self: stretch;

        .title {
            color: var(--Color-Zink-950, $main-active-color);

            font-size: 24px;
            font-family: $ff-bold;
            line-height: 125%; /* 30px */
            letter-spacing: -0.24px;
            text-transform: uppercase;
        }
        
        .realty-info {
            overflow: hidden;
            color: var(--Color-Zink-500, $secondary-text-color);
            text-overflow: ellipsis;

            /* Vuetify/text-body-1 */
            font-family: $ff-regular;
            font-style: normal;
            font-weight: 400;
            line-height: 125%; /* 20px */
            letter-spacing: -0.16px;
        }

        .description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            align-self: stretch;

            overflow: hidden;
            color: var(--Color-Zink-950, $main-active-color);
            text-overflow: ellipsis;

            font-size: 16px;
            line-height: 125%; /* 20px */
            letter-spacing: -0.16px;
            font-family: $ff-regular;
        }
    }

    .booking-info {
        display: flex;
        padding: var(--Padding-pa-6, 24px) var(--Padding-pa-4, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Padding-pa-6, 24px);
        align-self: stretch;

        border-radius: 16px;
        border: 1px solid var(--Color-Zink-200, $border-color);
        background: var(--Color-Zink-50, $main-card-color);

        .title{
            color: var(--Color-Zink-950, $main-active-color);

            font-family: var(--Kortros-font-family, Formular);
            font-size: 16px;
            font-family: $ff-bold;
            text-transform: uppercase;
        }

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
        }
    }
    .actions {
        min-width: 100%;

        .dialog-button {
            display: flex;
            min-width: 100%;
            height: 52px;
            padding: var(--Padding-pa-4, 16px);
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            align-self: stretch;
        }
    }
}
</style>